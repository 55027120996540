@import "./configs/color.css";

body,
html {
    font-family: "Roboto", sans-serif;
    background-color: $gris;
}

.react-date-picker {
    padding: 8px 5px 5px 13px;
    border: 1px solid rgb(133, 133, 133);
    border-radius: 5px;
}

.form-notice {
    text-align: center;
    color: #dc3545;
}

.bg-blanco {
    background-color: $blanco;
}

.content-wrapper {
    background-color: $gris;
    position: absolute;
    /* padding: 15px; */
    top: 44px;
    left: 300px;
    min-height: calc(100vh - 61px);
    -webkit-transition: left 0.4s;
    -moz-transition: left 0.4s;
    -ms-transition: left 0.4s;
    -o-transition: left 0.4s;
    transition: left 0.4s;
    right: 0;
    margin: auto;
    max-width: 1440px;
}

.content-wrapper-reduced {
    left: 75px !important;
}

.container {
    background-color: $gris;
}

.grid-titulo {
    font-size: 1.5rem;
    font-weight: bold;
    color: $negro-dark;
    border-bottom: 1.5px solid #e5e5e5;
    display: flex;
    min-width: 100%;
    justify-content: space-between;
    align-items: center;
}

.react-bs-table-tool-bar {
    width: 100%;
    padding: 10px 0 10px 0;
}

.grid-end-seccion {
    border-bottom: 3px solid $gris-dark;
}

.grid-border-right {
    border-right: 3px solid $gris-dark;
}

@media only screen and (max-width: 767px) {
    .grid-border-right {
        border-right: none;
    }

    .grid-titulo {
        font-size: 1.5rem;
    }
}

.grid-container {
    /* border-radius: 10px; */
    /* border: 2px solid #e8ebee; */
    /* margin-top: 1em; */
    padding: 10px 20px;
    background-color: $blanco;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.grid-container-mob {
    margin-top: 1em;
}

@media only screen and (max-width: 800px) {
    .grid-container {
        margin-top: 1em;
        background-color: transparent;
        border: 2px transparent;
        text-align: center;
        align-items: left;
    }
}

.padding-0 {
    padding: 0;
}

.padding-5 {
    padding: 5px;
}

.padding-10 {
    padding: 10px;
}

.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-top-0 {
    padding-top: 0 !important;
}

.padding-15 {
    padding: 15px;
}

.padding-20 {
    padding: 20px;
}

.em-1-1 {
    font-size: 1.1em;
}

.em-1-2 {
    font-size: 1.2em;
}

.em-1-3 {
    font-size: 1.3em;
}

.em-1-4 {
    font-size: 1.4em;
}

.em-1-5 {
    font-size: 1.5em;
}

.em-1-6 {
    font-size: 1.6em;
}

.em-1-7 {
    font-size: 1.7em;
}

.em-1-8 {
    font-size: 1.8em;
}

.em-1-9 {
    font-size: 1.9em;
}

.em-1-10 {
    font-size: 1.1em;
}

.em-2 {
    font-size: 2em;
}

.em-2-5 {
    font-size: 2.5em;
}

.em-3 {
    font-size: 3em;
}

/* MARGINS */
.margin-0 {
    margin: 0;
}

.mt-10 {
    margin-top: 10px;
}

.mt-40 {
    margin-top: 40px;
}

.ml-10 {
    margin-left: 10px;
}

.mh-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

/**
 * Custom form elements
 *    - Checkbox
 *    - Radios
 *    - Switch
 *    - Rounded inputs
 */
.react-bs-select-all {
    /* color: #bf3638; */
    width: 1.3em;
    height: 1.3em;
    display: grid;
}

td input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: transparent;
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    /* color: #bf3638; */
    width: 1.3em;
    height: 1.3em;
    border: 0.16em solid #bf3638;
    border-radius: 0.15em;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
}

td input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: white;
}

td input[type="checkbox"]:checked::before {
    transform: scale(1);
}

td input[type="checkbox"]:checked {
    background-color: #bf3638;
    color: white;
}

/* td input[type="checkbox"]:focus {
    background-color: #bf3638;
} */

td input[type="checkbox"]:disabled {
    border-color: #bf3638;
    cursor: not-allowed;
    opacity: 0.5;
}

.c-checkbox,
.c-radio {
    margin-right: 4px;
}

.c-checkbox *,
.c-radio * {
    cursor: pointer;
}

.c-checkbox input,
.c-radio input {
    opacity: 0;
    position: absolute;
    margin-left: 0 !important;
}

.c-checkbox span,
.c-radio span {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: 2px solid #bf3638;
    margin-right: 5px;
    text-align: center;
}

.c-checkbox span.white,
.c-radio span.white {
    background-color: #fff;
}

.c-checkbox span:before,
.c-radio span:before {
    margin-left: 1px;
}

.c-checkbox:hover span {
    border-color: #bf3638;
}

.c-radio:hover span {
    border-color: $naranja;
}

.form-inline .c-checkbox span,
.form-inline .c-radio span {
    margin-left: 0;
}

.c-checkbox.c-checkbox-rounded span,
.c-checkbox.c-radio-rounded span,
.c-radio.c-checkbox-rounded span,
.c-radio.c-radio-rounded span {
    border-radius: 500px;
}

/* override for radio */
.c-radio span {
    border-radius: 500px;
}

/* the icon */
.c-checkbox span:before,
.c-radio span:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    text-align: center !important;
    font-size: 12px;
    line-height: 18px;
    vertical-align: middle;
}

/* Checked state */
.c-checkbox,
.c-radio {
    /* override for radio */
    /* Disable state */
    /* override for radio */
}

.c-checkbox input[type="checkbox"]:checked + span:before,
.c-checkbox input[type="radio"]:checked + span:before,
.c-radio input[type="checkbox"]:checked + span:before,
.c-radio input[type="radio"]:checked + span:before {
    color: #fff;
    opacity: 1;
    transition: color 0.3s ease-out;
}

.lectura input[type="checkbox"]:checked + span:before,
.lectura input[type="radio"]:checked + span:before,
.lectura input[type="checkbox"]:checked + span:before,
.lectura input[type="radio"]:checked + span:before {
    color: $naranja;
    opacity: 1;
    transition: color 0.3s ease-out;
    cursor: initial;
}

.c-checkbox input[type="checkbox"]:checked + span,
.c-checkbox input[type="radio"]:checked + span,
.c-radio input[type="checkbox"]:checked + span,
.c-radio input[type="radio"]:checked + span {
    border-color: #bf3638;
    background-color: #bf3638;
}

.lectura input[type="checkbox"]:checked + span,
.lectura input[type="radio"]:checked + span,
.lectura input[type="checkbox"]:checked + span,
.lectura input[type="radio"]:checked + span {
    border-color: #fff !important;
    background-color: #eff3f6 !important;
    cursor: initial;
}

.lectura {
    cursor: initial !important;
}

.lec {
    border: 0px solid #eff3f6 !important;
}

.c-checkbox input[type="radio"]:checked + span,
.c-radio input[type="radio"]:checked + span {
    background-color: #fff;
}

.c-checkbox input[type="radio"]:checked + span:before,
.c-radio input[type="radio"]:checked + span:before {
    color: #bf3638;
}

.c-checkbox input[type="checkbox"]:disabled:checked + span,
.c-checkbox input[type="radio"]:disabled:checked + span,
.c-radio input[type="checkbox"]:disabled:checked + span,
.c-radio input[type="radio"]:disabled:checked + span {
    border-color: #bf3638 !important;
    background-color: #bf3638 !important;
    opacity: 0.65;
}
.c-checkbox input[type="checkbox"]:disabled + span,
.c-checkbox input[type="radio"]:disabled + span,
.c-radio input[type="checkbox"]:disabled + span,
.c-radio input[type="radio"]:disabled + span {
    background-color: white !important;
    opacity: 0.65;
}

.c-checkbox input[type="radio"]:disabled + span,
.c-radio input[type="radio"]:disabled + span {
    background-color: #fff !important;
}

.c-checkbox input[type="radio"]:disabled + span:before,
.c-radio input[type="radio"]:disabled + span:before {
    color: #000000;
}

.c-radio.c-radio-nofont {
    /* override for radio */
    /* Disable state */
    /* override for radio */
}

.c-radio.c-radio-nofont span:before {
    content: "";
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -5px;
    border-radius: 500px;
}

.c-radio.c-radio-nofont input[type="radio"]:checked + span:before {
    color: #fff;
    opacity: 1;
    transition: color 0.3s ease-out;
}

.c-radio.c-radio-nofont input[type="radio"]:checked + span {
    border-color: $primary;
    background-color: $primary;
}

.c-radio.c-radio-nofont input[type="radio"]:checked + span {
    background-color: #fff;
}

.c-radio.c-radio-nofont input[type="radio"]:checked + span:before {
    background-color: $primary;
}

.c-radio.c-radio-nofont input[type="radio"]:disabled + span {
    border-color: #ddd !important;
    background-color: #ddd !important;
}

.c-radio.c-radio-nofont input[type="radio"]:disabled + span {
    background-color: #fff !important;
}

.c-radio.c-radio-nofont input[type="radio"]:disabled + span:before {
    background-color: #ddd;
}

.Select.form-control {
    padding: 1px;
}

.Select-control {
    border: none !important;
    border-radius: 1em !important;
    height: auto !important;
    background-color: transparent !important;
}

.form-group .dinero {
    max-height: 75px;
    min-height: 75px;
    margin-top: 1em;
}

.form-group {
    margin-bottom: 15px;
    font-size: 0.8rem;
}

label {
    margin-bottom: 0.2rem;
}

.row-fields {
    margin-bottom: 15px;
}

/* Toogle switch */

.switch {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 28px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #bf3638;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: $primary;
}

input:focus + .slider {
    box-shadow: 0 0 1px $primary;
}

input:checked + .slider:before {
    -webkit-transform: translateX(42px);
    -ms-transform: translateX(42px);
    transform: translateX(42px);
}

/* Rounded sliders */

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/* ESTILOS PARA LAS MODALES */

.styles_modal__gNwvD {
    border-radius: 10px;
    padding: 0px;
    max-width: 80% !important;
    z-index: 999999 !important;
}

.styles_overlay__CLSq- {
    background: rgba(0, 0, 0, 0.5);
    z-index: 1050 !important;
}

.custom-modal {
    background: #fff;
    width: 100%;
}

.modal-width-50 {
    width: 50% !important;
}

.modal-container {
    border-radius: 10px;
    border: 2px solid #e8ebee;
    margin: 0;
    background-color: #ffffff;
}

.icon-img {
    max-width: 30px;
    cursor: pointer;
}

.modal-body {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    max-height: 550px;
}

.modal-footer {
    padding: 15px 0;
    width: 100%;
}

/*UPLOADER FILE*/
.icon-upload {
    width: 80px !important;
}

/**
 * react-bootstrap-table
*/
.react-bs-table-container {
    .table {
        thead {
            background-color: #dedede;
        }
    }

    .table-bordered {
        min-width: 850px;
    }

    .react-bs-container-header {
        .table th,
        .table td {
            padding: 0.4rem !important;
        }
    }

    .react-bs-container-body {
        table {
            tbody {
                tr:nth-child(even) {
                    background-color: #ececec;
                }

                tr:hover {
                    background-color: #0da1ff36 !important;
                }
            }

            th,
            td {
                padding: 0.4rem !important;
                vertical-align: middle;
            }
        }
    }

    font-size: 0.8rem;
}

.tabla-w-auto {
    .react-bs-table-container {
        .table-bordered {
            min-width: auto !important;
        }
    }
}

/**
* Para quitar el scroll horizontal de la tabla
*/
.tabla-auto {
    .table-bordered {
        min-width: auto !important;
    }
}

.imagen-sm {
    width: 65px;
    border: #dddddd solid 1px;
    border-radius: 50%;
    height: 65px;
    align-self: center;
}

.imagen-sm-50 {
    width: 50px;
    border: #dddddd solid 1px;
    border-radius: 50%;
    height: 50px;
}

.text-primary {
    color: #007bff;
}

/* Flex genericos */
.display-flex-width-100 {
    display: flex;
    width: 100%;
}

.flex-row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.flex-row-end {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.flex1 {
    flex: 1;
}

.flex-1 {
    flex: 1;
}

.flex-1-5 {
    flex: 1.5;
}

.flex-2 {
    flex: 2;
}

.flex-2-5 {
    flex: 2.5;
}

.flex-3 {
    flex: 3;
}

.flex-3-5 {
    flex: 3.5;
}

.flex-8 {
    flex: 8;
}

.width100 {
    width: 100%;
}

.select-precios {
    text-align: right;
    padding: 0 15px 0 10px;
    border-bottom: 1px solid #eff3f6;
    cursor: pointer;
}

.select-precios:hover {
    background-color: #e7e7e7;
}

.border-radius-top-squa {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.border-radius-buttom-squa {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.bold {
    font-weight: bold;
}

$orange-gradient: linear-gradient(to right, $orange1, $orange2);
$yellow1: #ffc121;
$yellow2: #e6a200;
$yellow-gradient: linear-gradient(to right, $yellow1, $yellow2);
$green1: #b6b640;
$green2: #7c7115;
$green3: #8da441;
$green-gradient: linear-gradient(to right, $green1, $green2);
$red1: #f65637;
$red2: #f50e03;
$red-gradient: linear-gradient(to right, $red1, $red2);

.green-container {
    /* border-radius: 2em; */
    text-align: center;
    margin-bottom: 16px;

    border: 0.07em solid $green3;
    border-radius: 0.5em;

    h3 {
        margin: 0;
        color: white;
        padding-top: 10px;
    }

    h5 {
        width: 80%;
        margin: 0 auto;
        padding: 0.5vh;
        background: $green3;
        border-bottom-right-radius: 0.5em;
        border-bottom-left-radius: 0.5em;
    }

    .container1 {
        padding-top: 15px;
        background: transparent;

        .container2 {
            position: relative;
            border-bottom-left-radius: 0.5em;
            border-bottom-right-radius: 0.5em;
            // height: 300px;
            background-color: #fff;
        }
    }
}

.red-container {
    /* border-radius: 2em; */
    text-align: center;
    margin-bottom: 16px;
    border: 0.07em solid #ff3190;
    border-radius: 0.5em;

    h3 {
        margin: 0;
        color: white;
        padding-top: 10px;
    }

    h5 {
        width: 80%;
        margin: 0 auto;
        padding: 0.5vh;
        background: #ff3190;
        border-bottom-right-radius: 0.5em;
        border-bottom-left-radius: 0.5em;
    }

    .container1 {
        padding-top: 15px;
        background: transparent;
        .container2 {
            position: relative;
            border-bottom-left-radius: 0.5em;
            border-bottom-right-radius: 0.5em;
            // height: 300px;
            background-color: #fff;
        }
    }
}

.hr-green {
    margin-top: 0;
    margin-bottom: 16px;
    height: 0.2em;
    background: linear-gradient(to right, $green1, $green2, $red1, $red2);
}

.hr-orange {
    margin-top: 0;
    margin-bottom: 16px;
    height: 0.2em;
    background: $orange-gradient;
}
.hr-negro-dark2 {
    margin-top: 0;
    margin-bottom: 16px;
    height: 0.2em;
    background: $negro-dark2;
}

.w100 {
    width: 100%;
}
@media only screen and (max-width: 767px) {
    .w100 {
        width: 65%;
    }
}

.card-list {
    padding: 0% 0% 0% 0%;
}

.card-list-item {
    padding: 2.5vh 0vw;
}

.card-ventas {
    overflow: hidden;
    margin-bottom: 1em;

    .item-card:first-child {
        position: absolute;
        border-bottom-right-radius: 4em;
        border-bottom-left-radius: 4em;
        background: #0087ff;
        z-index: 2;
        max-width: 22%;

        padding-left: 1.2em;
        padding-right: 1.2em;
        padding-top: 1em;
        height: 4.8em;
        text-align: center;

        img {
            position: relative;
            top: 25%;
            margin-top: -50%;
        }
    }

    .item-card:last-child {
        background: #ffffff;
        position: relative;
        border: 0.07em solid #0087ff;
        border-radius: 0.5em;
        z-index: 1;

        text-align: right;

        left: 12%;
        max-width: 88%;

        h6 {
            max-width: 85%;
            margin: auto;
            margin-bottom: 0%;
            padding: 0.7vh;
            padding-right: 6%;
            background: #0087ff;
            color: $blanco;
            border-top-right-radius: 0.7em;
            border-top-left-radius: 0.7em;
        }

        h4 {
            margin-bottom: 0%;
            margin-right: 13%;
            color: $negro-dark;
        }

        span {
            padding-right: 0em;
        }
    }
}

.hr-yellow {
    margin-top: 0;
    margin-bottom: 16px;
    height: 0.2em;
    background: $yellow-gradient;
}

.card-gastos {
    overflow: hidden;
    margin-bottom: 1em;

    .item-card:first-child {
        position: absolute;
        border-bottom-right-radius: 4em;
        border-bottom-left-radius: 4em;
        background: $primary;
        z-index: 2;
        max-width: 22%;

        padding-left: 1.2em;
        padding-right: 1.2em;
        padding-top: 1em;
        height: 4.8em;
        text-align: center;

        img {
            position: relative;
            top: 25%;
            margin-top: -50%;
        }
    }

    .item-card:last-child {
        background: #ffffff;
        position: relative;
        border: 0.07em solid $primary;
        border-radius: 0.5em;
        z-index: 1;

        text-align: right;

        left: 12%;
        max-width: 88%;

        h6 {
            max-width: 85%;
            margin: 0 auto;
            margin-bottom: 0%;
            padding: 0.7vh;
            padding-right: 6%;
            background: $primary;
            color: $negro-dark;
            border-top-right-radius: 0.7em;
            border-top-left-radius: 0.7em;
        }

        h4 {
            margin-bottom: 0%;
            margin-right: 13%;
            color: $negro-dark;
        }

        span {
            padding-right: 0em;
        }
    }
}

.font-normal {
    font-weight: normal !important;
}

/* CONTENEDOR DE TOTALES REPORTES */

.content-total-reports {
    border-top: 2px solid #dee2e6;
    border-bottom: 2px solid #dee2e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

/* ESTILOS PARA EL DROPDOWN */
.dda-container {
    background-color: #1f1f1f;
}

.dda-container .dda-dropdown-list {
    border: 0;
}

.dda-container .dda-dropdown-list > .seperator {
    background-color: #cacaca !important;
    height: 0px !important;
    border-bottom: 0.5px !important;
}

.table-bordered th,
.table-bordered td {
    border: 0 !important;
}

/* ANIMATIONS */

.opacityPulse-css {
    animation: Pulsate 1s linear infinite;
}

@keyframes Pulsate {
    from {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.pl-10 {
    padding-left: 10px !important;
}

.pl-40 {
    padding-left: 40px !important;
}

.pl-20 {
    padding-left: 20px !important;
}

.border-dotted {
    border: dotted 1px;
}

.r-encajar-listado-productos {
    max-height: calc(100vh - 19rem) !important;
    /* overflow-y: auto;
    overflow-x: hidden; */
}

/* VISTA PREVIA RECIBOS */
.container-vista-previa {
    border: 2px solid #e8ebee;
    background: #fff;
    border-radius: 10px;
}

.container-vista-previa-head {
    /* height: 7.5vh; */
    background-color: #fff;
    border-radius: 10px;
    padding: 30px 0 20px 20px;
}

.container-vista-previa-body {
    height: 70vh;
    overflow-y: auto;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    font-size: auto !important;
}

.swal-fracciones {
    .swal2-header {
        height: 0;
    }

    .swal2-actions {
        margin: 15px 0;
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: row;
    }
}

.iconos-swal-fracciones {
    max-width: 100px;
    max-height: 100px;
}

.backgr-naranja-degradado {
    background: linear-gradient(to right, $primary, $primary);
    color: #ffffff !important;
}

.modal-bienvenida {
    width: 80%;
}

.modal-ayuda {
    width: 80%;
    padding: 10px;
    border-radius: 0px;
}

.iDCJJE {
    position: fixed;
}

.white-space-normal {
    white-space: normal !important;
}

.bg-naranja {
    background-color: $primary !important;
}

.bg-container-new-prod-cotizacion {
    background-color: #0072ae !important;
}

.margin-left-5-naranja {
    border-left: 5px solid $primary;
}

.margin-left-5-container-new-prod-cotizacion {
    border-left: 5px solid #0072ae;
}

.border-radius-1 {
    border-radius: 10px;
    border: 1px solid #c5c5c5;
}

.span-checkbox-exclude span {
    width: auto;
    height: auto;
    border: 0;
    border-radius: 0;
    margin: 0;
}

.content-txt-anulacion {
    position: absolute;
    background-color: #de0000;
    color: #fff;
    padding: 2px 5px;
    font-weight: 600;
    right: 17px;
    font-size: 15px;
}

.word-break-all {
    word-break: break-all !important;
}

.word-break {
    word-break: break-word !important;
}

.swal2-container {
    z-index: 99999;
}

.a-remove-fromat {
    color: inherit;
    text-decoration: none;
}

.a-remove-fromat:hover,
.a-remove-fromat:active {
    color: inherit;
    text-decoration: none;
}

/* BACKGROUNDS */
.bg-primary {
    background-color: $primary !important;
}
.bg-primary2 {
    background-color: $primary2 !important;
}

.text-underline {
    text-decoration: line-through !important;
}

.text-gray {
    color: #8a8a8a !important;
}
@media only screen and (max-width: 768px) {
    .modal-width-50 {
        width: 100% !important;
    }

    .modal-sm {
        max-width: 100% !important;
    }

    .modal-bienvenida {
        width: 100% !important;
        max-width: 100% !important;
    }

    .modal-ayuda {
        width: 100% !important;
        max-width: 100% !important;
    }

    .swal-fracciones {
        .swal2-header {
            height: 0;
        }

        .swal2-actions {
            display: flex;
            align-content: center;
            justify-content: center;
            flex-direction: column;
        }
    }

    .react-bs-table-container {
        .table-bordered {
            min-width: 850px !important;
        }
    }

    .pagination {
        font-size: 10px;
    }

    .styles_modal__gNwvD {
        max-width: 100% !important;
    }

    .styles_overlay__CLSq- {
        padding: 15px !important;
    }

    .tabla-w-auto {
        .react-bs-table-container {
            .table-bordered {
                min-width: 850px !important;
            }
        }
    }

    .card-listado-prods {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 2px solid $gris;
        border-radius: 10px;
    }
    .card-listado-prods.card-header,
    .card-listado-prods.card-body {
        border: 0;
    }
    .buttons-venta {
        justify-content: center;
    }

    .buttons-venta div {
        margin: 1px;
    }

    @media only screen and (min-width: 340px) {
        .buttons-venta {
            display: flex;
        }
    }

    .monto-total {
        display: flex;
        justify-content: space-between;
        align-items: left;
        padding: 10px;
        height: 50px;
    }

    .cobroEfectivoTarjeta {
        display: flex;
        flex-direction: row;
    }

    @media only screen and (max-width: 400px) {
        .cobroEfectivoTarjeta {
            flex-direction: column;
        }
    }
}

.react-bs-table-container .material-icons {
    margin-top: 5px !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
    font-size: 30px !important;
    color: #57535a !important;
}

.icon-exito {
    color: #ef5543 !important;
    display: inline-block !important;
}

.main-container-g {
    display: flex !important;
    flex-direction: row !important;
}

.text-white {
    color: #ffffff !important;
}

.react-date-picker__wrapper {
    border: none;
    margin-top: -3.7px;
}

.disabled-date-picker {
    background-color: #e6e6e6;
    cursor: not-allowed;
}

/* .form-control{
    border-color: #c4c4c4 !important;
} */
