/* Grid de recibo */
.grid-recibo {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    /* grid-template-rows: 1fr 1fr 1fr; */
    gap: 10px 20px;
    padding-bottom: 10px;
}

.grid-recibo b {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px !important;
}

.align-end {
    justify-self: end;
}

.header-grid-recibo {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px 15px;
}
