@import './configs/color.css';

.content-tabs {
    width: 100%;
}
.tab_list {
    margin: 0;
    padding: 0;
}

.content-tab-venta {
    padding:  5px 0;
    border-bottom: 4px solid #B8B9BB;
    list-style-type: none;
    font-weight: bolder;
    color: #B8B9BB;
    font-size: 1rem;
    cursor: pointer;
}
.content-tab-venta-active {
    border-bottom: 4px solid $negro-dark;
    color: $negro-dark;
}

.content-tab-naranja {
    list-style-type: none;
    padding:  8px 0;
    background-color: $primary;
    color: $blanco;
    font-weight: bold;
    cursor: pointer;
    border-top: 2px solid $primary;
    border-left: 2px solid $primary;
    border-right: 2px solid $primary;
    border-radius: 4px 4px 2px 2px;
}

.content-tab-naranja-active {
    background-color: $blanco;
    color: $negro-dark2;
    border-top: 2px solid $primary;
    border-left: 2px solid $primary;
    border-right: 2px solid $primary;
    border-bottom-left-radius: 0 ;
    border-bottom-right-radius: 0;
}
.react-tabs__tab--disabled {
    opacity: 0.75;
    cursor: not-allowed !important;
}

.underline-row {
    text-decoration: line-through !important;
}
