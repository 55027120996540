.upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F5F5F5;
    margin: 0;
    /* max-width: 25rem; */
    min-width: 100%;
    height: 12rem;
    border: 1px solid #E2E2E2;
    border-radius: 1rem;
    /* box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25); */
    position: relative;
    overflow: hidden;
}

.upload-text {
    text-align: center;
    /* font-size: 1rem; */
    font-weight: normal;
    /* @media (max-width: 576px) {
        font-size: 0.8rem !important;
    } */
    /* @media (max-width: 868px) {
        font-size: 1rem;
    } */
}

.uploaded-file-image {
    max-width: 100%;
}
.max-size-img{
    height: 50%;
}
.uploaded-file-text {
    /* font-size: 15px; */
    /* font-weight: bold; */
}
.uploaded-image-container {
    display: none;
    position: absolute;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat';
    border-radius: 1rem;
    color: #2f2f2f;
    background-color: #c9d1de9a;
    width: 100%;
    height: 100%;
    top: 0px;
}
