.minute textarea.form-control {
    max-width: 100%;
}

.minute div.upload-container {
    max-width: 100%;
}

.icon-exito {
    color: #ef5543 !important;
    display: inline-block !important;
}

.btn-old-files {
    background-color: #e0e0e0 !important;
}

.main-container-g {
    display: flex !important;
    flex-direction: row !important;
}

.multi-file-box-a {
    text-align: justify !important;
}
.multi-file-box-b {
    text-align: justify !important;
}

.DisabledBeneficiaryModal {
    /*max-width: 440px !important;
    max-height: 230px !important;
    width: 100%; */
}
