@import "../../../../style/configs/color.css";
.sidebar {
    position: fixed;
    top: 0;
    width: 200px;
    bottom: 0;
    background-color: $naranja;
    padding-top: 15px;
    z-index: 500;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-transition: width 0.4s;
    -moz-transition: width 0.4s;
    -ms-transition: width 0.4s;
    -o-transition: width 0.4s;
    transition: width 0.4s;
    .img-activo {
          display: none;
    }
    .v-menu {
        background: $naranja;
    }
    .activo {
        background: $naranja-oscuro;
        font-weight: normal;
        border-left: 4px solid #0DA1FF;
        .img-activo {
              display: inline;
        }
        .img-inactivo {
            display: none;
        }
    }
    .menu-item:hover {
        background: $naranja-oscuro;
    }
}

.sidebar-reduced {
    width: 100px !important;
    .v-menu {
        background: $naranja;
        width: 200px !important;
    }
    .v-menu.collapsed {
        width: 0 !important;
    }
    img +div{
        display: none;
    }
    .imagen-sidebar {
        img {
            width: 45px;
        }
    }
    .sidebar-item {
        border-left: 0;
    }
    .accordion__arrow {
        display: none;
    }

}

.sidebar img.sub-item {
    margin-right: 5px;
}
.sidebar img {
    width: 25px;
}

.sidebar-item {
    border-left: 4px solid transparent;
    display: block;
    padding: 5px 13px;
    color: #000000;
}

.imagen-sidebar {
    img {
        border: 0px solid rgba(0, 0, 0, 0.4);
        width: 100%;
    }
}

.no-border-sidebar {
    border: 0 solid transparent !important;
}
