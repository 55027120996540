@import "../../node_modules/react-select/dist/react-select.css";
@import "../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css";
@import "../../node_modules/react-dropdown-advanced/styles/rdropdown.css";
@import "../../node_modules/react-day-picker/lib/style.css";
@import "../../node_modules/react-checkbox-tree/lib/react-checkbox-tree";

@import "configs/color";
@import "_custom_styles_btn";
@import "bootstrap_reset.css";
@import "_custom_global_styles";
@import "_utils";
@import "_custom_styles_controls";
@import "_scrollbar.css";
@import "_custom_factura";
@import "_custom_tab";
@import "_custom_editor_wysiwyg";
@import "_pagination";
/*NODE MODULES CSS*/

@import "impresion";
/* @import "./components/index.css"; */
html {
    font-size: 1rem;
}
.container {
    /*font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;*/
    font-size: 16px;
    margin-left: 60px;
    margin-right: 60px;
}

/*Style preloader*/

.loader-container {
    min-height: 140px;
}

.loader {
    border: 7px solid #f3f3f3;
    border-radius: 50%;
    border-top: 7px solid #3498db;
    width: 60px;
    height: 60px;
    left: 50%;
    background-color: #e0e0e0;
    position: absolute;
    margin: 1rem 1rem 1rem -30px;
    padding: 1rem;
    -webkit-animation: spin 1.5s linear infinite;
    /* Safari */
    animation: spin 1.5s linear infinite;
}

.small-loader {
    border: 7px solid #f3f3f3;
    border-radius: 50%;
    border-top: 7px solid #3498db;
    width: 30px;
    height: 30px;
    background-color: #e0e0e0;
    position: absolute;
    padding: 1rem;
    -webkit-animation: spin 1.5s linear infinite;
    /* Safari */
    animation: spin 1.5s linear infinite;
    margin: 1rem 1rem 1rem -30px;
    left: 40%;
}

.card.card-small {
    border: 0;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.toasts-container {
    max-height: 100px;
}

.txt-yellow {
    color: #ff9100;
}

@media screen and (min-width: 768px) {
    .flex-md-0 {
        flex: none !important;
    }
    .w-md-0 {
        width: auto !important;
    }
}

/* Safari */

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .topnavbar {
        min-height: 43px;
        padding: 0.2rem 0.5rem 0 0.5rem !important;
    }
    .content-wrapper {
        top: 44px;
    }
}
@media only screen and (max-width: 767px) {
    .container {
        margin-left: 0;
        margin-right: 0;
    }
    .sidebar {
        display: none !important;
    }
    .content-wrapper {
        left: 0 !important;
        width: 100%;
        padding: 20px 0 0;
        top: 53px;
    }
}

.border-bottom.blue {
    border-bottom: 1px solid #136ac6 !important;
    color: #136ac6;
}

.border-datos-feel {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding-top: 20px;
    margin-bottom: 10px;
}

.title-text {
    color: #000;
    font-size: 1.6rem;
    font-weight: bold;
}

.border-right-2 {
    border-right: 2.5px solid #c3c6c9 !important;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: rgb(222, 226, 230);
}

@media only screen and (max-width: 767px) {
    .border-right-2 {
        border-right: 0px solid #c3c6c9 !important;
    }
}
