/* * Estilos del titulo con borde */
.titulo-borde {
    position: relative;
    --border: 3px solid #136ac6;
    color: #136ac6;
    border-right: var(--border);
    border-bottom: var(--border);
    box-shadow: 3.3px 2px 4px rgba(0, 0, 0, 0.25);
    background-color: transparent !important;
    max-width: max-content;
    padding: 5px 25px;
}
.titulo-borde::before {
    /* posicionamiento */
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 3px;
    width: 100%;
    /* Estilos */
    background-color: #136ac6;
    box-shadow: 3.3px 2px 4px rgba(0, 0, 0, 0.25);
}

.txt-italic {
    font-style: italic !important;
}

.titulo-3 {
    font-size: 2.25rem;
    font-weight: 500;
}
