/* #date_picker {
    .react-date-picker__wrapper{
        border: 0;
    }
    .react-date-picker__inputGroup__input {
        color: #495057;
    }
    .react-date-picker__inputGroup__day {
        width: 20px !important;
    }
    .react-date-picker__inputGroup__month {
        width: 20px !important;
    }
    .react-date-picker__inputGroup__year {
        width: 43px !important;
    }
    .react-date-picker__inputGroup__leadingZero + .react-date-picker__inputGroup__day {
        width: 10px !important;
    }
    .react-date-picker__inputGroup__leadingZero + .react-date-picker__inputGroup__month {
        width: 10px !important;
    }
}

@media only screen and (max-width: 575px) {
    #date_picker {
        .react-calendar {
            width: 265px !important;
        }
    }
} */
