/* ENCABEZADO */
#IMPRESION-HOJA-OV {
    /*display: flex;*/
    flex-direction: column;
    align-items: center;
}
#IMPRESION-HOJA-OV .hoja-carta .encabezado {
    display: flex;
    margin-right: 1cm;
}

#IMPRESION-HOJA-OV .hoja-carta .encabezado .item {
    flex: 1;
    background-color: #ededed;
    margin: 1cm 7.5px 0 7.5px;
    padding: 15px 7.5px;
    font-size: 14px;
}

#IMPRESION-HOJA-OV .hoja-carta .encabezado .item-img img {
    width: 100%;
}

/* INFO OV */
#IMPRESION-HOJA-OV .hoja-carta .info-ov {
    padding: 0 1cm;
    display: flex;
}

#IMPRESION-HOJA-OV .hoja-carta .info-ov .info-ov-item {
    flex: 1;
    padding: 0 7.5px;
}
#IMPRESION-HOJA-OV .hoja-carta .info-ov .info-ov-item:first-child {
    padding-left: 0;
}
#IMPRESION-HOJA-OV .hoja-carta .info-ov .info-ov-item:last-child {
    padding-right: 0;
}

#IMPRESION-HOJA-OV .hoja-carta .info-ov .info-ov-item .title {
    font-weight: bold;
    font-size: 15px;
    text-transform: uppercase;
    border-bottom: 3px solid #ededed;
}

#IMPRESION-HOJA-OV .hoja-carta .info-ov .info-ov-item .info-ov-item-body {
    padding: 7.5px 7.5px;
}
#IMPRESION-HOJA-OV .hoja-carta .info-ov .info-ov-item .info-ov-item-body .item {
    flex: 1;
    p {
        margin-bottom: 5px;
        font-size: 12px;
    }
    b {
        font-size: 12px;
    }
    span {
        font-size: 12px;
    }
}

/* PRODUCTOS OV */
#IMPRESION-HOJA-OV .hoja-carta .productos-ov {
    margin-top: 15px;
    padding: 0 1cm;
}

#IMPRESION-HOJA-OV .hoja-carta .productos-ov table thead {
    /*background-color: #ededed;*/
    border-bottom: 2px solid black;
    font-size: 12px;
}

#IMPRESION-HOJA-OV .hoja-carta .productos-ov table tbody tr:nth-child(even) {
    background-color: #ededed;
}
#IMPRESION-HOJA-OV .hoja-carta .productos-ov .table thead tr th {
    text-transform: uppercase;
}
#IMPRESION-HOJA-OV .hoja-carta .productos-ov .table thead tr th,
#IMPRESION-HOJA-OV .hoja-carta .productos-ov .table tbody tr td {
    border: 0 !important;
    font-size: 10px !important;
}

@media print {
    #IMPRESION-HOJA-OV .hoja-carta .encabezado {
        display: flex;
        margin-right: 1cm;
    }
    #IMPRESION-HOJA-OV .hoja-carta .encabezado .item-img {
        flex: 3;
    }
    #IMPRESION-HOJA-OV .hoja-carta .encabezado .item-img img {
        width: 100%;
    }
    #IMPRESION-HOJA-OV .hoja-carta .encabezado .item {
        flex: 1;
        background-color: #ededed;
        margin: 1cm 7.5px 0 7.5px;
        padding: 15px 7.5px;
        font-size: 14px;
        -webkit-print-color-adjust: exact;
    }

    /* INFO OV */
    #IMPRESION-HOJA-OV .hoja-carta .info-ov {
        padding: 0 1cm;
        display: flex;
    }

    #IMPRESION-HOJA-OV .hoja-carta .info-ov .info-ov-item {
        flex: 1;
        padding: 0 7.5px;
    }
    #IMPRESION-HOJA-OV .hoja-carta .info-ov .info-ov-item:first-child {
        padding-left: 0;
    }
    #IMPRESION-HOJA-OV .hoja-carta .info-ov .info-ov-item:last-child {
        padding-right: 0;
    }

    #IMPRESION-HOJA-OV .hoja-carta .info-ov .info-ov-item .title {
        font-weight: bold;
        font-size: 10px !important;
        text-transform: uppercase;
        border-bottom: 3px solid #ededed;
    }

    #IMPRESION-HOJA-OV .hoja-carta .info-ov .info-ov-item .info-ov-item-body {
        padding: 7.5px 7.5px;
    }
    #IMPRESION-HOJA-OV
        .hoja-carta
        .info-ov
        .info-ov-item
        .info-ov-item-body
        .item {
        flex: 1;
        font-size: 12px !important;
    }

    #IMPRESION-HOJA-OV
        .hoja-carta
        .info-ov
        .info-ov-item
        .info-ov-item-body
        .item
        p {
        margin-bottom: 5px;
    }

    /* PRODUCTOS OV */
    #IMPRESION-HOJA-OV .hoja-carta .productos-ov {
        margin-top: 15px;
        padding: 0 1cm;
    }

    #IMPRESION-HOJA-OV .hoja-carta .productos-ov .table thead tr th {
        text-transform: uppercase;
        background-color: #ededed !important;
        -webkit-print-color-adjust: exact;
    }
    #IMPRESION-HOJA-OV
        .hoja-carta
        .productos-ov
        table
        tbody
        tr:nth-child(even)
        td {
        background-color: #ededed !important;
    }
    #IMPRESION-HOJA-OV .hoja-carta .productos-ov .table thead tr th {
        background-color: #ededed;
        -webkit-print-color-adjust: exact;
    }
    #IMPRESION-HOJA-OV .hoja-carta .productos-ov .table thead tr th,
    #IMPRESION-HOJA-OV .hoja-carta .productos-ov .table tbody tr td {
        font-size: 12px !important;
        border: 0 !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }


    /*#IMPRESION-HOJA-OV .line {
        font-weight: bold;
        font-size: 10px !important;
        text-transform: uppercase;
        border-bottom: 3px solid #bb1818;
    }*/

}

#IMPRESION-HOJA-OV .hoja-carta .info-ov .info-ov-item .info-ov-item-body .footer-text {
    flex: 1;
    font-size: 10px !important;
}

