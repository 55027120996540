:root{
    /* Colores - colores */
    --blue: #136AC6;
    --blue-opacity: #B1CBE7;
    --blue-navbar: #A6C0DB;
    --red: #BF3638;
    --red-opacity: #E5BCBC;
    --black: #000000;
    --gray: #ACACAC;
    --gray-mid: #57535A;
    --bg-color: #F5F5F5;
    --white10: #FFFFFF;
    --white20: #FAFAFA;
    /* Colores - alertas */
    --warning: #E69B20;
    --success: #19B335;
    --error: #DB371F;

    /* Texto - tamaños */
    --txt-1: 4rem;
    --txt-2: 3rem;
    --txt-3: 2.25rem;
    --txt-4: 1.5rem;
    --txt-5: 1.125rem;
    --txt-6: 0.875rem;
    --txt-7: 0.75rem;

    /* Texto - weight */
    --ligth: 300;
    --regular: 400;
    --semibold: 500;
    --bold: 700;
    --extrabold: 900;

    /* Sombras - box shadow */
    --shadow: 3.3px 2px 4px rgba(0, 0, 0, 0.25);
}