@import "../../../../../style/configs/color.css";

.react-bs-table-tool-bar {
    background-color: $blanco;
    border-radius: 5px;
    padding: 8px 10px;
}
.toolbar {
    color: $negro;
    font-size: 1.5rem;
    font-weight: bold;
}
.toolbar img {
    max-width: 20px;
}
/* .toolbar .btn{
    border: $negro solid 2px;
    color: $negro;
}
.toolbar .btn:hover{
    border: $negro solid 2px;
}
.toolbar .btn a:hover{
    text-decoration: none;
} */
.icon-search {
    position: absolute;
    top: 8px;
    right: 15px;
}
.icon-search-ayuda {
    right: 15px !important;
}
.toolbar-search {
    font-size: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
}
.toolbar-search .fa {
    right: 15px;
    /* color: $gris-dark !important; */
}
.toolbar-search input {
    background-color: $blanco !important;
    color: $negro-dark2 !important;
    padding-right: 2rem;
}
.toolbar-search input:focus {
    background-color: $blanco !important;
    color: $negro-dark2 !important;
}
.toolbar .filtro {
    font-size: 18px;
    font-weight: normal;
}
.toolbar .Select {
    color: $negro-dark2;
    background-color: $blanco;
}
.toolbar .Select-control {
    background-color: $blanco;
    color: $negro-dark2;
    .Select-value {
        color: $negro-dark2 !important;
        .Select-value-label {
            color: $negro-dark2 !important;
        }
    }
}
.toolbar .Select.is-open > .Select-control {
    background-color: $blanco;
    color: $negro;
}
.toolbar .Select.is-focused > .Select-control {
    background: $blanco;
    color: $negro;
}
.toolbar .Select.is-focused:not(.is-open) > .Select-control {
    background: $blanco;
    color: $negro;
}
.toolbar .Select-option.is-focused {
    background-color: $blanco;
    color: $negro;
}
.toolbar .Select-arrow {
    border-color: $negro transparent transparent;
}
.toolbar .Select .Select-arrow-zone:hover > .Select-arrow {
    border-top-color: $gris;
}
.toolbar
    .Select.has-value.Select--single
    > .Select-control
    .Select-value
    a.Select-value-label:focus,
.Select.has-value.is-pseudo-focused.Select--single
    > .Select-control
    .Select-value
    a.Select-value-label:focus {
    background: $blanco;
}
.toolbar .Select-clear {
    color: $negro;
}
.toolbar .Select-clear:hover {
    color: $gris;
}
.toolbar .Select-placeholder {
    color: $negro;
}
.toolbar .Select-option.is-selected {
    color: $negro;
    background-color: $blanco;
}
.toolbar .Select-noresults {
    color: $negro;
    background-color: $blanco;
}
.toolbar .Select-option.is-focused {
    color: $negro;
}
.toolbar .Select-option {
    color: $negro;
    background-color: $blanco;
}

@media only screen and (max-width: 575px) {
    .toolbar-search {
        margin-top: 15px;
        margin-left: 0;
    }
}

@media only screen and (max-width: 768px) {
    /* .icon-search-ayuda {
        right: 24px !important;
    } */
}
