@import '../../../../style/configs/color.css';
/* ========================================================================
     Component: top-navbar
 ========================================================================== */
.topnavbar-wrapper{
    position: fixed;
    left: 0 !important;
    right: 0 !important;
    z-index: 50;
    border-bottom: 1px solid $negro;
    -webkit-transition: left 0.4s;
    -moz-transition: left 0.4s;
    -ms-transition: left 0.4s;
    -o-transition: left 0.4s;
    transition: left 0.4s;
}
.topnavbar {
    -webkit-backface-visibility: hidden;
    /* fixes chrome jump */
    margin-bottom: 0;
    border-radius: 0;
    background-color: #fff;
    z-index: 1000;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.navbar{
    /* padding-left: 0 !important; */
    background: $color-navbar !important;
}
@media only screen and (min-width: 768px) {
    .topnavbar .navbar-header {
        width: 220px;
        text-align: center;
    }
    .topnavbar .navbar-header .navbar-brand {
        width: 100%;
    }
    .navbar {
        /* padding-left: 0 !important; */
        min-height: 60px;
    }
}

.topnavbar {
    position: fixed;
    width: 100%;
}
.topnavbar .navbar-header {
    background-color: transparent;
    background-image: -webkit-linear-gradient(left, #fff 0%, #fff 100%);
    background-image: -o-linear-gradient(left, #fff 0%, #fff 100%);
    background-image: linear-gradient(to right, #fff 0%, #fff 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFFFFFFF', GradientType=1);
}
@media only screen and (min-width: 768px) {
    .topnavbar .navbar-header {
        background-image: none;
    }
}
.topnavbar .navbar-header {
    position: relative;
    z-index: 11;
}
.topnavbar .navbar-header .navbar-brand {
    padding: 0;
}
.topnavbar .navbar-header .brand-logo > img,
.topnavbar .navbar-header .brand-logo-collapsed > img {
    margin: 0 auto;
}
.topnavbar .navbar-header .brand-logo {
    display: block;
    padding: 10px 15px;
}
.topnavbar .navbar-header .brand-logo-collapsed {
    display: none;
    padding: 6px 15px;
}
.topnavbar .navbar-nav > li > a,
.topnavbar .navbar-nav > .open > a {
    color: #000;
}
.topnavbar .navbar-nav > li > a:hover, .topnavbar .navbar-nav > li > a:focus,
.topnavbar .navbar-nav > .open > a:hover,
.topnavbar .navbar-nav > .open > a:focus {
    color: #cccccc;
}
.topnavbar .navbar-nav > .active > a, .topnavbar .navbar-nav > .active > a:hover, .topnavbar .navbar-nav > .active > a:focus,
.topnavbar .navbar-nav > .open > a,
.topnavbar .navbar-nav > .open > a:hover,
.topnavbar .navbar-nav > .open > a:focus {
    background-color: transparent;
}
.topnavbar .navbar-nav > li > [data-toggle='navbar-search'] {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    font-size: 16px;
    line-height: 85px;
    color: #fff;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-transition: color 0.3s ease;
    -o-transition: color 0.3s ease;
    transition: color 0.3s ease;
}
@media only screen and (min-width: 768px) {
    .topnavbar .navbar-nav > li > [data-toggle='navbar-search'] {
        color: #515253;
    }
}

.nav-wrapper {
    /* padding: 0 15px; */
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.container-logo-sucursal {
    display: flex
}
.nav-wrapper .nav.navbar-nav {
    float: left;
}
.nav-wrapper .nav.navbar-nav.navbar-right {
    float: right;
}
.nav-wrapper .nav > li {
    position: static;
    float: left;
}
.nav-wrapper .navbar-nav .open .dropdown-menu {
    position: absolute;
    background-color: white;
    left: 0px;
    right: 0px;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
}
.navbar-nav {
    margin: 0 !important;
}

.topnavbar .navbar-form {
    position: absolute;
    top: -100%;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    height: 85px;
    z-index: 9001;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    border-bottom: 1px solid #e1e2e3;
}
.topnavbar .navbar-form .form-group {
    height: 100%;
    width: 100%;
}
.topnavbar .navbar-form .form-control, .topnavbar .navbar-form body .bootstrap-tagsinput, body .topnavbar .navbar-form .bootstrap-tagsinput {
    height: 100%;
    border: 0;
    border-radius: 0;
    width: 100%;
}
.topnavbar .navbar-form.open {
    top: 0;
}
.topnavbar .navbar-form .has-feedback .form-control-feedback {
    height: 30px;
    cursor: pointer;
    top: 50%;
    margin-top: -15px;
    line-height: 30px;
    margin-right: 10px;
    color: #c1c2c3;
    font-size: 1.5em;
    pointer-events: auto;
}

/* drowdown del usuario */
.container-drop-usuario {
    align-self: flex-end;
    position: absolute;
    cursor: pointer;
}

.dopdown-usuario {
    background-color: transparent;
    color: $negro;
    font-weight: bold;
}
.container-no-foto {
    background-color: #FFF;
    width: 40px !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}

@media only screen and (max-width: 575px) {
    .container-no-foto {
        margin-right: 0;
    }
}

@media only screen and (min-width: 768px) {
    .topnavbar .navbar-form {
        left: 220px;
    }
}

@media only screen and (min-width: 768px) {
    .topnavbar {
        border: 0;
        background-color: #fff;
        background-image: -webkit-linear-gradient(left, #fff 0%, #fff 100%);
        background-image: -o-linear-gradient(left, #fff 0%, #fff 100%);
        background-image: linear-gradient(to right, #fff 0%, #fff 100%);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFFFFFFF', GradientType=1);
    }
    .topnavbar .navbar-header {
        background-image: none;
        background-repeat: no-repeat;
        filter: none;
    }
    .topnavbar .navbar-nav > a {
        -webkit-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s;
    }
    .topnavbar .navbar-nav > .open > a, .topnavbar .navbar-nav > .open > a:hover, .topnavbar .navbar-nav > .open > a:focus {
        -webkit-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s;
    }
    .topnavbar .navbar-nav > li > a,
    .topnavbar .navbar-nav > .open > a {
        color: #515253;
    }
    .topnavbar .navbar-nav > li > a:hover, .topnavbar .navbar-nav > li > a:focus,
    .topnavbar .navbar-nav > .open > a:hover,
    .topnavbar .navbar-nav > .open > a:focus {
        color: #cccccc;
    }
    .topnavbar .navbar-nav > li > [data-toggle='navbar-search'] {
        position: static;
    }

    .nav-wrapper {
        position: relative;
        z-index: 10;
    }
    .nav-wrapper:before, .nav-wrapper:after {
        content: " ";
        display: table;
    }
    .nav-wrapper:after {
        clear: both;
    }
    .nav-wrapper .nav > li {
        position: relative;
    }
    .nav-wrapper .navbar-nav .open .dropdown-menu {
        left: auto;
        right: auto;
    }
    .nav-wrapper .navbar-nav.navbar-right .open .dropdown-menu {
        left: auto;
        right: 0;
    }
}
@media only screen and (min-width: 768px) {
    .aside-collapsed .topnavbar .navbar-header .brand-logo {
        display: none;
    }
    .aside-collapsed .topnavbar .navbar-header .brand-logo-collapsed {
        display: block;
    }
    .aside-collapsed .topnavbar .navbar-header {
        width: 70px;
    }
    .aside-collapsed .topnavbar .navbar-form {
        left: 70px;
    }
    .topnavbar-wrapper {
        left: 200px !important;
    }
}
.topnavbar-wrapper img{
    max-height: 45px;
}

.sucursal-select {
    background: transparent;
    border-bottom: solid 2px $negro;
}
.topnavbar-wrapper-reduced{
    left: 75px !important;
    right: 0 !important;
}

.banderas-navbar{
    background-color: #F2F2F2;
    top: 0;
    left: 0;
    padding-top: 13px;
    padding-left: 15px;
    min-width: 115px;
}